import {
    EditProfileProps,
    GetAccessProps,
    GetAppleTokenProps,
    GetFacebookTokenProps,
    GetGoogleTokenProps,
    SignInProps,
    SignUpProps,
} from '../models/auth-models'
import { store } from '../store'
import {
    changeScreen,
    logOut,
    setAccessToken,
    setUserInfo,
} from '../store/auth/auth-action'
import { setTransactions } from '../store/transactions/transaction-action'
import customFetcher from '../utils/customFetcher'
import { AccessToken, Category } from './../types/api'
import { API_HOST, API_VERSION, CLIENT_ID, CLIENT_SECRET } from './config'

export const getAccessTokenSignUp = async (body: GetAccessProps) => {
    const { response, data } = await customFetcher(`/oauth/v2/token`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'Content-Type': 'application/json',
            'Accept-Language': 'en',
        },
    })
    if (response.status === 200) {
        store.dispatch(setAccessToken(data))
    }
}

export const signUp = async (
    body: SignUpProps,
    access_token: string,
    setError?: (error: string) => void
) => {
    const info = {
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
        grant_type: 'password',
        scope: 'ios',
        username: body.email,
        password: body.password,
    }
    const { response, data } = await customFetcher(
        `/api/${API_VERSION}/application-users`,
        {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }
    )
    if (response.status === 201) {
        store.dispatch(setUserInfo(data))
        getAccessTokenSignIn(info)
        store.dispatch(changeScreen('Finish Sign Up'))
    } else {
        if (setError) {
            if (data.violations?.fields?.email) {
                setError(data.violations.fields.email.errors[0].message)
            } else if (data.violations.minLength) {
                setError(data.violations.minLength[0].password)
            } else if (data.violations.fields.password_confirmation) {
                setError(
                    data.violations.fields.password_confirmation.errors[0]
                        .message
                )
            }
        }
    }
}

export const getUserProfile = async (
    access_token: string,
    nextScreen?: string
) => {
    const { screen } = store.getState().auth
    const { response, data } = await customFetcher(
        `/api/${API_VERSION}/application-users/me`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }
    )
    if (response.status === 200) {
        store.dispatch(setUserInfo(data))
        if (screen !== 'Finish Sign Up') {
            store.dispatch(
                changeScreen(nextScreen ? nextScreen : 'Menu Screen')
            )
        }
    }
}

export const getAccessTokenSignIn = async (
    body: SignInProps,
    setError?: (error: string) => void
) => {
    const { response, data } = await customFetcher(`/oauth/v2/token`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'Content-Type': 'application/json',
        },
    })
    if (response.status === 200) {
        store.dispatch(setAccessToken(data))
        getUserProfile(data.access_token, 'Menu Screen')
    } else {
        if (setError) setError(data.error_description)
    }
}

export const getTokenWithFacebook = async (body: GetFacebookTokenProps) => {
    const { response, data } = await customFetcher(`/oauth/v2/token`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'Content-Type': 'application/json',
        },
    })
    if (response.status === 200) {
        store.dispatch(setAccessToken(data))
        getUserProfile(data.access_token, 'Menu Screen')
    }
}

export const getTokenWithGoogle = async (body: GetGoogleTokenProps) => {
    const { response, data } = await customFetcher(`/oauth/v2/token`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'Content-Type': 'application/json',
        },
    })
    if (response.status === 200) {
        store.dispatch(setAccessToken(data))
        getUserProfile(data.access_token, 'Menu Screen')
    }
}

export const getTokenWithApple = async (body: GetAppleTokenProps) => {
    const { response, data } = await customFetcher(`/oauth/v2/token`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'Content-Type': 'application/json',
        },
    })
    if (response.status === 200) {
        store.dispatch(setAccessToken(data))
        getUserProfile(data.access_token, 'Menu Screen')
    }
}

export const resetPassword = async (email: string, access_token: string) => {
    fetch(`${API_HOST}/api/${API_VERSION}/reset-password/${email}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${access_token}`,
        },
    }).then((res) => res.json())
}

export const getProjectById = async (access_token: string, id: string) => {
    const { response, data } = await customFetcher(
        `/api/${API_VERSION}/project/${id}`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }
    )
    if (response.status === 200) {
        return data
    }
}

export const getAllProjects = async (access_token: string) => {
    const { response, data } = await customFetcher(
        `/api/${API_VERSION}/project?limit=100`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }
    )
    if (response.status === 200) {
        return data.results
        // store.dispatch(setImpacts(data.results));
    }
}

export const deleteOwnAccount = async (access_token: string) => {
    const { response } = await customFetcher(
        `/api/${API_VERSION}/application-users/me`,
        {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        }
    )
    if (response.status == 200 || response.status == 204) {
        store.dispatch(changeScreen('Start Screen'))
        store.dispatch(logOut())
    }
}

export const editOwnProfile = async (
    body: EditProfileProps,
    access_token: AccessToken
) => {
    const { response, data } = await customFetcher(
        `/api/${API_VERSION}/application-users/me`,
        {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${access_token.access_token}`,
            },
        }
    )
    if (response.status === 200) {
        store.dispatch(setUserInfo(data))
        return data
    }
}

export const uploadFile = async (
    file: any,
    access_token: string,
    setError: (error: string) => void
) => {
    const { response, data } = await customFetcher(
        `/api/${API_VERSION}/files`,
        {
            method: 'POST',
            body: file,
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        }
    )
    if (response.status === 201) {
        return data
    } else {
        setError(data.violations[0].message)
    }
}

export const getAllPhotos = async (
    access_token: string,
    limit: number,
    offset: number,
    country: string
) => {
    const { response, data } = await customFetcher(
        `/api/${API_VERSION}/corporate-partners/category/all?limit=${limit}&offset=${offset}${
            country && `&filter[country]=${country}`
        }`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }
    )
    if (response.status === 200) {
        // store.dispatch(setPhotos(data.results));
        return data
    }
}
export const getAllAdditionalPhotos = async (
    access_token: string,
    limit: number,
    offset: number,
    country: string
) => {
    const { response, data } = await customFetcher(
        `/api/${API_VERSION}/corporate-partners/category/all?limit=${limit}&offset=${offset}${
            country && `&filter[country]=${country}`
        }`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }
    )
    if (response.status === 200) {
        // store.dispatch(setAdditionalPhotos(data.results));
        return data.results
    }
}
export const getAllDefaultPhotos = async (
    access_token: string,
    limit: number,
    offset: number,
    country: string
) => {
    const { response, data } = await customFetcher(
        `/api/${API_VERSION}/site/corporate-partners/all?limit=${limit}&offset=${offset}${
            country && `&filter[country]=${country}`
        }`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }
    )
    if (response.status === 200) {
        return data
        // store.dispatch(setDefaultPhotos(data.results));
    }
}
export const getAllAdditionalDefaultPhotos = async (
    access_token: string,
    limit: number,
    offset: number,
    country: string
) => {
    const { response, data } = await customFetcher(
        `/api/${API_VERSION}/site/corporate-partners/all?limit=${limit}&offset=${offset}${
            country && `&filter[country]=${country}`
        }`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }
    )
    if (response.status === 200) {
        return data.results
    }
}

export const getUserSavings = async (access_token: string, year: number) => {
    const { response, data } = await customFetcher(
        `/api/${API_VERSION}/user-saving?filter[year]=${year}`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }
    )

    if (response.status === 200) {
        return data
    }
}

export const getTransactions = async (access_token: string) => {
    const { response, data } = await customFetcher(
        `/api/${API_VERSION}/user-loyalty-transactions?limit=10`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }
    )
    if (response.status === 200) {
        store.dispatch(setTransactions(data.results))
    }
}

export const donateProject = async (
    amount: { amount: number },
    access_token: string,
    id: string
) => {
    const { response } = await customFetcher(
        `/api/${API_VERSION}/project/${id}/donate`,
        {
            method: 'POST',
            body: JSON.stringify(amount),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }
    )
    if (response.status === 201) {
        getUserProfile(access_token, 'Thanks for donate')
    }
}

export const getLink = async (id: string, access_token: string) => {
    const { data } = await customFetcher(
        `/api/${API_VERSION}/corporate-partners/${id}/affiliate-link`,
        {
            method: 'GET',
            headers: {
                'Accept-Language': 'en',
                Authorization: `Bearer ${access_token}`,
            },
        }
    )
    return data.link
}

export const getCategories = async () => {
    return await fetch(`${API_HOST}/api/${API_VERSION}/site/categories`, {
        method: 'GET',
        headers: {
            'Accept-Language': 'en',
        },
    })
        .then((response) => response.json())
        .then((data) => {
            // store.dispatch(setCategories(data))
            return data as Array<Category>
        })
}

export const filterCategories = async (
    id: string,
    limit: number,
    offset: number,
    country: string
) => {
    return await fetch(
        `${API_HOST}/api/${API_VERSION}/site/corporate-partners/${id}?limit=${limit}&offset=${offset}${
            country && `&filter[country]=${country}`
        }`,
        {
            method: 'GET',
            headers: {
                'Accept-Language': 'en',
            },
        }
    )
        .then((response) => response.json())
        .then((data) => {
            return data.results
        })
}
export const filterLoginedCategories = async (
    id: string,
    limit: number,
    offset: number,
    access_token: string,
    country: string
) => {
    const { response, data } = await customFetcher(
        `/api/${API_VERSION}/corporate-partners/category/${id}?limit=${limit}&offset=${offset}${
            country && `&filter[country]=${country}`
        }`,
        {
            method: 'GET',
            headers: {
                'Accept-Language': 'en',
                Authorization: `Bearer ${access_token}`,
            },
        }
    )
    if (response.status === 200) {
        return data.results
    }
}

export const searchDefault = async (
    name: string,
    limit: number,
    offset: number,
    country: string
) => {
    const { response, data } = await customFetcher(
        `/api/${API_VERSION}/site/corporate-partners/all?limit=${limit}&offset=${offset}&filter[name]=${name}${
            country && `&filter[country]=${country}`
        }`,
        {
            method: 'GET',
            headers: {
                'Accept-Language': 'en',
            },
        }
    )
    if (response.status === 200) {
        return data.results
    }
}
export const searchAll = async (
    access_token: string,
    name: string,
    limit: number,
    offset: number,
    country: string
) => {
    const { response, data } = await customFetcher(
        `/api/${API_VERSION}/corporate-partners/category/all?limit=${limit}&offset=${offset}&filter[name]=${name}${
            country && `&filter[country]=${country}`
        }`,
        {
            method: 'GET',
            headers: {
                'Accept-Language': 'en',
                Authorization: `Bearer ${access_token}`,
            },
        }
    )
    console.log('Привіт із SearchAll')

    if (response.status === 200) {
        return data.results
    }
}

export const getInterests = async (access_token: string) => {
    const { response, data } = await customFetcher(
        `/api/${API_VERSION}/interests`,
        {
            method: 'GET',
            headers: {
                'Accept-Language': 'en',
                Authorization: `Bearer ${access_token}`,
            },
        }
    )
    if (response.status === 200) {
        return data.results
    }
}

// without typing due to the lack of documentation
export const getCorporateDetailsByName = async (
    access_token: string,
    name: string
) => {
    const { response, data } = await customFetcher(
        `/api/${API_VERSION}/corporate-partners/by-name/${name}`,
        {
            method: 'GET',
            headers: {
                'Accept-Language': 'en',
                Authorization: `Bearer ${access_token}`,
            },
        }
    )
    if (response.status === 200) {
        return data
    }
}

export const followFeatures = async (access_token: string, id: string) => {
    const { response, data } = await customFetcher(
        `/api/${API_VERSION}/corporate-partners/${id}/favorite`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': 'en',
                Authorization: `Bearer ${access_token}`,
            },
        }
    )
    if (response.status === 200) {
        return data
    }
}
export const unfollowFeatures = async (access_token: string, id: string) => {
    const { response, data } = await customFetcher(
        `/api/${API_VERSION}/corporate-partners/${id}/favorite`,
        {
            method: 'DELETE',
            headers: {
                'Accept-Language': 'en',
                Authorization: `Bearer ${access_token}`,
            },
        }
    )
    if (response.status === 200) {
        return data
    }
}
export const getFollowings = async (
    access_token: string,
    limit: number,
    offset: number
) => {
    const { response, data } = await customFetcher(
        `/api/${API_VERSION}/corporate-partners/favorites?limit=${limit}&offset${offset}`,
        {
            method: 'GET',
            headers: {
                'Accept-Language': 'en',
                Authorization: `Bearer ${access_token}`,
            },
        }
    )
    if (response.status === 200) {
        return data
    }
}
